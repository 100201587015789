import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import DetailItem from "../../Products/DetailItem";
import ProductStateBadge from "../../Products/ProductStateBadge";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cart";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import DOMPurify from "dompurify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductDetailsCard = ({ setOpen, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  console.log("Product ID:", data._id);

  const productUrl = `/product/${data._id}`;

  const handleMessageSubmit = () => {};

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const createMarkup = (htmlContent) => {
    return {
      __html: DOMPurify.sanitize(htmlContent),
    };
  };

  const truncateDescription = (desc, length = 100) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = desc;
    let truncatedText = tempDiv.textContent || tempDiv.innerText || "";

    if (truncatedText.length > length) {
      return truncatedText.substring(0, length) + "...";
    }
    return truncatedText;
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("¡El artículo ya está en el carrito!");
    } else {
      if (data.stock < count) {
        toast.error("¡Stock del producto limitado!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("¡Artículo añadido al carrito con éxito!");
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist, data._id]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return ReactDOM.createPortal(
    <div className="bg-[#fff]">
      {data ? (
        <div className="fixed w-full h-screen top-0 left-0 bg-[#00000030] z-40 flex items-center justify-center">
          <div className="w-[90%] md:w-[60%] h-[90vh] overflow-y-scroll md:h-[75vh] bg-white rounded-md shadow-sm relative p-4">
            <RxCross1
              size={30}
              className="absolute right-3 top-3 z-50"
              onClick={() => setOpen(false)}
            />

            <div className="block w-full md:flex">
              <div className="w-full md:w-[50%]">
                <Link to={productUrl}>
                  <LazyLoadImage
                    src={data.images?.[0]?.url}
                    alt=""
                    effect="blur"
                    loading="lazy"
                  />
                </Link>
                <div className="flex items-center pt-12">
                  <Link to={`/shop/preview/${data?.shop._id}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div className="pr-8">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3
                        className={`${styles.shop_name} font-Poppins pb-1 pt-1`}
                      >
                        {data.shop.name}
                      </h3>
                    </Link>
                  </div>
                  <button
                    className="flex ml-5 shadow-md items-center justify-center border-2 border-blue-600 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                    onClick={handleMessageSubmit}
                  >
                    <AiOutlineMessage size={25} />
                  </button>
                </div>
              </div>

              <div className="w-full md:w-[50%] pt-5 pl-3">
                <Link to={productUrl}>
                  <h1 className="text-lg md:text-xl font-bold">{data.name}</h1>
                  <hr className="my-2" />
                  <p className="font-Poppins font-medium text-sm text-gray-600">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        truncateDescription(data.description)
                      )}
                    />
                  </p>
                </Link>
                <ProductStateBadge estado={data.productState} />
                <hr className="my-2" />
                {/* Detalles del producto */}
                <div className="flex flex-wrap gap-2 mt-4">
                  <DetailItem
                    iconId={data.sector}
                    text={data.sector}
                    //onClick={() => handleSearch('sector', data.sector)}
                  />
                  <DetailItem
                    iconId={data.category}
                    text={data.category}
                    //onClick={() => handleSearch('category', data.category)}
                  />
                  <DetailItem
                    iconId={data.subCategory}
                    text={data.subCategory}
                    //onClick={() => handleSearch('subCategory', data.subCategory)}
                  />
                  <DetailItem
                    iconId={data.item}
                    text={data.item}
                    //onClick={() => handleSearch('item', data.item)}
                  />
                </div>
                <div className="text-sm pt-3 ml-2 md:ml-28">
                  <h4 className="text-lg md:text-3xl font-semibold text-green-600 mr-4">
                    {data.discountPrice}€
                  </h4>
                  {data.originalPrice && (
                    <h3 className="text-md md:text-xl line-through text-gray-500">
                      {data.originalPrice}€
                    </h3>
                  )}
                </div>

                <div className="flex  mt-12">
                  <div className="flex items-center">
                    {click ? (
                      <button
                        className="flex mr-5 shadow-md items-center justify-center bg-transparent border-2 border-red-500 text-red-500 py-2 px-4 rounded hover:bg-red-500 hover:text-white transition duration-300"
                        onClick={() => removeFromWishlistHandler(data)}
                        title="Quitar de la lista de deseos"
                      >
                        <AiFillHeart size={24} />
                      </button>
                    ) : (
                      <button
                        className="flex mr-5 shadow-md items-center justify-center bg-transparent border-2 border-gray-400 text-gray-400 py-2 px-4 rounded hover:bg-gray-400 hover:text-white transition duration-300"
                        onClick={() => addToWishlistHandler(data)}
                        title="Añadir a la lista de deseos"
                      >
                        <AiOutlineHeart size={24} />
                      </button>
                    )}
                  </div>
                  <div>
                    <button
                      className="flex ml-5 shadow-md items-center justify-center border-2 border-blue-600 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                      onClick={() => addToCartHandler(data._id)}
                      title="Añadir al Carrito"
                    >
                      <AiOutlineShoppingCart size={25} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>,
    document.getElementById("portal-root")
  );
};

export default ProductDetailsCard;
