import axios from "axios";
import { server } from "../../server";

// create product
export const createProduct =
  (
    name,
    description,
    productState,
    sector,
    category,
    subCategory,
    item,
    tags,
    originalPrice,
    discountPrice,
    stock,
    shopId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "productCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/product/create-product`,
        name,
        description,
        productState,
        sector,
        category,
        subCategory,
        item,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId,
        images,
      );
      dispatch({
        type: "productCreateSuccess",
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: "productCreateFail",
        payload: error.response.data.message,
      });
    }
  };

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteProductRequest" });

    const { data } = await axios.delete(`${server}/product/delete-shop-product/${id}`, { withCredentials: true });

    dispatch({
      type: "deleteProductSuccess",
      payload: { message: data.message, id: id } // Asegúrate de enviar el ID y el mensaje
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// Acción para restablecer el estado de éxito
export const resetProductSuccess = () => async (dispatch) => {
  dispatch({
    type: "productResetSuccess",
  });
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};

// Search products
export const searchProducts = (searchParams) => async (dispatch) => {
  try {
    dispatch({ type: "searchProductsRequest" });

    const queryString = new URLSearchParams(searchParams).toString();
    const { data } = await axios.get(`${server}/product/search?${queryString}`);

    dispatch({
      type: "searchProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "searchProductsFail",
      payload: error.response.data.message,
    });
  }
};
