import React from "react";
import { FaStar, FaWrench, FaRecycle } from "react-icons/fa";

const ProductStateBadge = ({ estado }) => {
  if (estado == null) {
    estado = "nuevo";
  }

  const estilosEstado = {
    nuevo: {
      fondo: "bg-green-100",
      texto: "text-green-800",
      icono: <FaStar />, // Icono para "nuevo"
    },
    usado: {
      fondo: "bg-yellow-100",
      texto: "text-yellow-800",
      icono: <FaWrench />, // Icono para "usado"
    },
    reacondicionado: {
      fondo: "bg-blue-100",
      texto: "text-blue-800",
      icono: <FaRecycle />, // Icono para "reacondicionado"
    },
  };

  const estadoActual = estilosEstado[estado];

  return (
    <div
      className={`flex items-center justify-end ${estadoActual.fondo} ${estadoActual.texto} border p-1 rounded-lg shadow-sm max-w-max`}
    >
      {estadoActual.icono}
      <span className="ml-1 capitalize">{estado}</span>
    </div>
  );
};

export default ProductStateBadge;
