import React from "react";
import { categories } from "../../static/allCategories";
import { GiGearHammer } from "react-icons/gi";

const IconComponent = ({ id, className }) => {
  const Icon =
    categories.find((icon) => icon.label === id)?.icon || GiGearHammer;

  return (
    <div>
      {/* Ya no es necesario hacer una verificación adicional para Icon, ya que siempre será un componente válido */}
      <Icon size={26} className={`${className} text-slate-800`} />
    </div>
  );
};

export default IconComponent;
