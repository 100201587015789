import React, { useState, useMemo } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import ProductStateBadge from "../../Products/ProductStateBadge";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const isWishlistItem = useMemo(
    () => wishlist.some((i) => i._id === data._id),
    [wishlist, data._id]
  );

  const [click, setClick] = useState(isWishlistItem);
  const [open, setOpen] = useState(false);

  const toggleWishlistHandler = () => {
    setClick((prevClick) => !prevClick);
    click ? dispatch(removeFromWishlist(data)) : dispatch(addToWishlist(data));
  };

  const addToCartHandler = () => {
    const isItemExists = cart.some((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("¡Artículo ya en el carrito!");
    } else if (data.stock < 1) {
      toast.error("¡Stock del producto limitado!");
    } else {
      dispatch(addTocart({ ...data, qty: 1 }));
      toast.success("¡Artículo agregado al carrito con éxito!");
    }
  };

  const {
    _id,
    images,
    name,
    ratings,
    originalPrice,
    discountPrice,
    sold_out,
    shop,
  } = data;

  const productUrl = `/product/${_id}${isEvent ? "?isEvent=true" : ""}`;
  const shopUrl = `/shop/preview/${shop?._id}`;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-transform duration-300 cursor-pointer w-full h-auto p-2 mb-1">
        <div className="product-image">
          <Link to={productUrl} onClick={scrollToTop}>
            <LazyLoadImage
              src={images?.[0]?.url || ""}
              alt={name}
              effect="blur"
              loading="lazy"
              className="w-full h-[150px] object-cover rounded-md transition-transform duration-300"
            />
          </Link>
        </div>
        <div className="mt-2">
          <Link to={shopUrl} onClick={scrollToTop}>
            <h5 className="text-[12px] md:text-[14px] text-blue-400 text-end">
              {shop?.name}
            </h5>
          </Link>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="flex-grow">
              <Link to={productUrl} onClick={scrollToTop}>
                <h4 className="text-sm md:text-base font-medium">
                  {name.length > 30 ? `${name.slice(0, 30)}...` : name}
                </h4>
              </Link>
              <ProductStateBadge estado={data.productState} />
              <div className="py-1 flex items-center justify-between">
                <div className="flex">
                  <h5
                    className={`${styles.productDiscountPrice} text-xs md:text-sm`}
                  >
                    {originalPrice === 0 ? originalPrice : discountPrice}€
                  </h5>
                  {originalPrice && (
                    <h4
                      className={`${styles.price} text-xs md:text-sm`}
                    >{`${originalPrice}€`}</h4>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <AiFillHeart
              size={20}
              className="cursor-pointer"
              onClick={toggleWishlistHandler}
              color={click ? "red" : "#333"}
              title={
                click
                  ? "Quitar de la lista de deseos"
                  : "Añadir a la lista de deseos"
              }
            />
            <AiOutlineEye
              size={20}
              className="cursor-pointer"
              onClick={() => setOpen(!open)}
              color="#333"
              title="Vista rápida"
            />
            <AiOutlineShoppingCart
              size={20}
              className="cursor-pointer"
              onClick={addToCartHandler}
              color="#444"
              title="Añadir al carrito"
            />
          </div>
        </div>
        {open && <ProductDetailsCard setOpen={setOpen} data={data} />}
      </div>
    </>
  );
};

export default ProductCard;
