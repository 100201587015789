import {
  GiBulldozer,
  GiForklift,
  GiDjedPillar,
  GiMiningHelmet,
  GiCrush,
  GiLaserTurret,
  GiMineTruck,
  GiPlow,
  GiPlantWatering,
  GiSeedling,
  GiFertilizerBag,
  GiGreenhouse,
  GiCow,
  GiForest,
  GiGardeningShears,
  GiGloves,
  GiSewingMachine,
  GiFactoryArm,
  GiPizzaCutter,
  GiIonCannonBlast,
  GiBottleVapors,
  GiMeltingMetal,
  GiTurnstile,
  GiTruck,
  GiCargoShip,
  GiCommercialAirplane,
  GiCctvCamera,
  GiGps,
  GiOilPump,
  GiElectric,
  GiMining,
  GiMineWagon,
  GiOilRig,
  GiMightySpanner,
  GiRadioTower,
  GiGameConsole,
} from "react-icons/gi";
import {
  FaToolbox,
  FaMicrosoft,
  FaTractor,
  FaUnsplash,
  FaSolarPanel,
  FaMaskVentilator,
  FaMicrochip,
  FaRegHospital,
  FaComputer,
  FaHelmetSafety,
  FaTruckMoving,
  FaHotel,
  FaMicroscope,
} from "react-icons/fa6";
import { LuConstruction, LuForklift } from "react-icons/lu";
import {
  MdAgriculture,
  MdOutlinePrecisionManufacturing,
  MdOutlineFitnessCenter,
  MdOutlineCastForEducation,
  MdBusiness,
} from "react-icons/md";
import { ImPrinter } from "react-icons/im";
import { PiAirTrafficControlFill } from "react-icons/pi";
import { BsBookshelf } from "react-icons/bs";
import { GrFanOption, GrTestDesktop } from "react-icons/gr";
import { FaPallet, FaIndustry } from "react-icons/fa";
import { SiJasmine, SiMinetest, SiOpnsense } from "react-icons/si";
import { GiPowerGenerator } from "react-icons/gi";

export const categories = [
  {
    label: "Maquinaria Pesada",
    icon: GiBulldozer,
    description: "",
  },
  {
    label: "Equipos de Elevación y Transporte",
    icon: GiForklift,
    description: "",
  },
  {
    label: "Herramientas y Equipamiento Menor",
    icon: FaToolbox,
    description: "",
  },
  {
    label: "Sistemas de Encofrado y Apuntalamiento",
    icon: GiDjedPillar,
    description: "",
  },
  {
    label: "Seguridad y Protección en la Obra",
    icon: GiMiningHelmet,
    description: "",
  },
  {
    label: "Maquinaria para el Tratamiento de Materiales",
    icon: GiCrush,
    description: "",
  },
  {
    label: "Equipos de Medición y Topografía",
    icon: GiLaserTurret,
    description: "",
  },
  {
    label: "Vehículos Especiales y Maquinaria Auxiliar",
    icon: GiMineTruck,
    description: "",
  },
  {
    label: "Sistemas y Materiales de Construcción",
    icon: LuConstruction,
    description: "",
  },
  {
    label: "Gestión y Software de Construcción",
    icon: FaMicrosoft,
    description: "",
  },
  {
    label: "Tractores y Maquinaria Agrícola",
    icon: FaTractor,
    description: "",
  },
  {
    label: "Maquinaria de Labranza",
    icon: GiPlow,
    description: "",
  },
  {
    label: "Sistemas de Riego",
    icon: GiPlantWatering,
    description: "",
  },
  {
    label: "Herramientas de Jardinería",
    icon: GiGardeningShears,
    description: "",
  },
  {
    label: "Equipos de Cultivo y Cosecha",
    icon: GiSeedling,
    description: "",
  },
  {
    label: "Fertilización y Control de Plagas",
    icon: GiFertilizerBag,
    description: "",
  },
  {
    label: "Equipos de Cultivo para Invernaderos",
    icon: GiGreenhouse,
    description: "",
  },
  {
    label: "Equipamiento para Ganadería",
    icon: GiCow,
    description: "",
  },
  {
    label: "Maquinaria Forestal",
    icon: GiForest,
    description: "",
  },
  {
    label: "Equipos de Jardinería Urbana",
    icon: GiGardeningShears,
    description: "",
  },
  {
    label: "Tecnología para Agricultura",
    icon: MdAgriculture,
    description: "",
  },
  {
    label: "Equipos de Protección y Seguridad",
    icon: GiGloves,
    description: "",
  },
  {
    label: "Maquinaria de Producción",
    icon: GiSewingMachine,
    description: "",
  },
  {
    label: "Montaje y Ensamblaje",
    icon: GiFactoryArm,
    description: "",
  },
  {
    label: "Herramientas de Corte de Precisión",
    icon: GiPizzaCutter,
    description: "",
  },
  {
    label: "Equipos de Procesamiento de Plástico",
    icon: FaUnsplash,
    description: "",
  },
  {
    label: "Equipos de Impresión y Etiquetado",
    icon: ImPrinter,
    description: "",
  },
  {
    label: "Equipos de Control de Calidad",
    icon: PiAirTrafficControlFill,
    description: "",
  },
  {
    label: "Equipos de Manipulación de Materiales",
    icon: LuForklift,
    description: "",
  },
  {
    label: "Automatización Industrial",
    icon: MdOutlinePrecisionManufacturing,
    description: "",
  },
  {
    label: "Equipos de Almacenamiento y Logística",
    icon: BsBookshelf,
    description: "",
  },
  {
    label: "Equipos de Tratamiento de Superficies",
    icon: GiIonCannonBlast,
    description: "",
  },
  {
    label: "Equipos de Extracción de Polvo y Humos",
    icon: GrFanOption,
    description: "",
  },
  {
    label: "Equipos de Envasado y Etiquetado",
    icon: GiBottleVapors,
    description: "",
  },
  {
    label: "Equipos de Procesamiento de Metales",
    icon: GiMeltingMetal,
    description: "",
  },
  {
    label: "Equipos de Fresado y Torneado",
    icon: GiTurnstile,
    description: "",
  },
  {
    label: "Transporte Terrestre",
    icon: GiTruck,
    description: "",
  },
  {
    label: "Transporte Marítimo",
    icon: GiCargoShip,
    description: "",
  },
  {
    label: "Transporte Aéreo",
    icon: GiCommercialAirplane,
    description: "",
  },
  {
    label: "Logística y Almacenamiento",
    icon: FaPallet,
    description: "",
  },
  {
    label: "Seguridad y Control de Acceso",
    icon: GiCctvCamera,
    description: "",
  },
  {
    label: "Navegación y GPS",
    icon: GiGps,
    description: "",
  },
  {
    label: "Maquinaria de Extracción y Perforación",
    icon: GiOilPump,
    description: "",
  },
  {
    label: "Generación de Energía",
    icon: GiElectric,
    description: "",
  },
  {
    label: "Equipos de Procesamiento de Minerales",
    icon: SiJasmine,
    description: "",
  },
  {
    label: "Herramientas y Equipos de Minería",
    icon: GiMining,
    description: "",
  },
  {
    label: "Transporte y Almacenamiento de Minerales",
    icon: GiMineWagon,
    description: "",
  },
  {
    label: "Equipos de Refinación y Procesamiento",
    icon: GiOilRig,
    description: "",
  },
  {
    label: "Tecnología y Automatización",
    icon: GiMightySpanner,
    description: "",
  },
  {
    label: "Energía Renovable",
    icon: FaSolarPanel,
    description: "",
  },
  {
    label: "Equipos de Seguridad y Control de Contaminación",
    icon: FaMaskVentilator,
    description: "",
  },
  {
    label: "Investigación y Desarrollo",
    icon: SiMinetest,
    description: "",
  },
  {
    label: "Comunicación y Redes",
    icon: GiRadioTower,
    description: "",
  },
  {
    label: "Electrónica Industrial",
    icon: FaMicrochip,
    description: "",
  },
  {
    label: "Equipos de Laboratorio y Médico",
    icon: FaRegHospital,
    description: "",
  },
  {
    label: "Equipos de Diagnóstico Médico",
    icon: GrTestDesktop,
    description: "",
  },
  {
    label: "Electrónica de Entretenimiento",
    icon: GiGameConsole,
    description: "",
  },
  {
    label: "Electrónica de Seguridad",
    icon: SiOpnsense,
    description: "",
  },
  {
    label: "Electrónica de Oficina y Data",
    icon: FaComputer,
    description: "",
  },
  {
    label: "Construcción y Obra Civil",
    icon: FaHelmetSafety,
    description: "",
  },
  {
    label: "Agricultura y Jardinería",
    icon: MdAgriculture,
    description: "",
  },
  {
    label: "Industria y Manufactura",
    icon: FaIndustry,
    description: "",
  },
  {
    label: "Transporte y Logística",
    icon: FaTruckMoving,
    description: "",
  },
  {
    label: "Energía y Minería",
    icon: GiPowerGenerator,
    description: "",
  },
  {
    label: "Tecnología y Electrónica",
    icon: FaMicrochip,
    description: "",
  },
  {
    label: "Hostelería y Restauración",
    icon: FaHotel,
    description: "",
  },
  {
    label: "Sanidad y Laboratorio",
    icon: FaMicroscope,
    description: "",
  },
  {
    label: "Deporte y Recreación",
    icon: MdOutlineFitnessCenter,
    description: "",
  },
  {
    label: "Educación y Formación",
    icon: MdOutlineCastForEducation,
    description: "",
  },
  {
    label: "Oficina y Negocios",
    icon: MdBusiness,
    description: "",
  },
];
