import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MyE from "../../Assests/MyE.png";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import Tooltip from "@mui/material/Tooltip";

const AdminHeader = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="w-full h-[70px] bg-white shadow-md sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div className="flex items-center">
        <Link to="/">
          <img
            className="w-[40px] h-[40px] mr-2"
            src={MyE}
            alt="Logo de Maquinarias y Equipos"
          />
        </Link>
        <Link to="/">
          <span className="font-bold text-xl mb-3 mt-2 text-blue-500">
            Maquinarias y Equipos
          </span>
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Tooltip title="Cupones">
            <Link to="/dashboard/cupouns" className="800px:block hidden">
              <CardGiftcardOutlinedIcon
                color="primary"
                fontSize="medium"
                className="mx-5 cursor-pointer hover:text-blue-800"
              />
            </Link>
          </Tooltip>
          <Tooltip title="Eventos">
            <Link
              to="/dashboard-events"
              className="800px:block hidden"
            >
              <LocalOfferOutlinedIcon
                color="primary"
                fontSize="medium"
                className="mx-5 cursor-pointer hover:text-blue-800"
              />
            </Link>
          </Tooltip>
          <Tooltip title="Productos">
            <Link
              to="/dashboard-products"
              className="800px:block hidden"
            >
              <InventoryOutlinedIcon
                color="primary"
                fontSize="medium"
                className="mx-5 cursor-pointer hover:text-blue-800"
              />
            </Link>
          </Tooltip>
          <Tooltip title="Pedidos">
            <Link
              to="/dashboard-orders"
              className="800px:block hidden"
            >
              <ShoppingCartCheckoutOutlinedIcon
                color="primary"
                fontSize="medium"
                className="mx-5 cursor-pointer hover:text-blue-800"
              />
            </Link>
          </Tooltip>
          <Tooltip title="Mensajes">
            <Link
              to="/dashboard-messages"
              className="800px:block hidden"
            >
              <ForumOutlinedIcon
                color="primary"
                fontSize="medium"
                className="mx-5 cursor-pointer hover:text-blue-800"
              />
            </Link>
          </Tooltip>
          <Link to={`/profile`}>
          <img
            src={`${user?.avatar?.url}`}
            alt=""
            className="w-[50px] h-[50px] rounded-full object-cover"
          />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
