import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";
import ProductCard from "../Route/ProductCard/ProductCard";
import Ratings from "../Products/Ratings";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import styles from "../../styles/styles";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";

const ShopProfileData = ({ isOwner }) => {
  const { products } = useSelector((state) => state.products);
  const { events } = useSelector((state) => state.events);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(id));
    dispatch(getAllEventsShop(id));
  }, [dispatch, id]);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const allReviews =
    products && products.map((product) => product.reviews).flat();

    const isMobileView = useMobileView();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
        {isOwner && (
          <div>
            <Link to="/dashboard">
              <div
                className={`${styles.button} !rounded-[4px] h-[42px] bg-blue-600 hover:bg-blue-700`}
              >
                <span className="text-[#fff]">Ir al panel</span>
              </div>
            </Link>
          </div>
        )}
      </Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="icon tabs example">
          <Tab
              icon={<ManageAccountsOutlinedIcon />}
              label={isMobileView ? "" : "Productos y Servicios"}
              value="1"
            />
            <Tab
              icon={<CategoryOutlinedIcon />}
              label={isMobileView ? "" : "Subastas en ejecución"}
              value="2"
            />
            <Tab
              icon={<StarHalfOutlinedIcon />}
              label={isMobileView ? "" : "Reseñas de la tienda"}
              value="3"
            />
          </TabList>
        </Box>

        <TabPanel value="1">
          {/* Pestaña de Productos */}
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-5 lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-6 2xl:grid-cols-6 mb-12 border-0">
            {products &&
              products.map((product, index) => (
                <ProductCard data={product} key={index} isShop={true} />
              ))}
          </div>
        </TabPanel>
        <TabPanel value="2">
          {/* Pestaña de Eventos */}
          <div className="grid grid-cols-2 gap-5 md:grid-cols-3 md:gap-6 lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-7 2xl:grid-cols-6 mb-12 border-0">
            {events &&
              events.map((event, index) => (
                <ProductCard
                  data={event}
                  key={index}
                  isShop={true}
                  isEvent={true}
                />
              ))}
            {events && events.length === 0 && (
              <p>No hay subastas para esta tienda.</p>
            )}
          </div>
        </TabPanel>
        <TabPanel value="3">
          {/* Pestaña de Reseñas */}
          <div className="w-full">
            {allReviews &&
              allReviews.map((review, index) => (
                <div key={index} className="w-full flex my-4">
                  <img
                    src={`${review.user.avatar?.url}`}
                    className="w-[50px] h-[50px] rounded-full"
                    alt=""
                  />
                  <div className="pl-2">
                    <div className="flex w-full items-center">
                      <h1 className="font-[600] pr-2">{review.user.name}</h1>
                      <Ratings rating={review.rating} />
                    </div>
                    <p className="font-[400] text-[#000000a7]">
                      {review?.comment}
                    </p>
                    <p className="text-[#000000a7] text-[14px]">
                      {"hace 2 días"}
                    </p>
                  </div>
                </div>
              ))}
            {allReviews && allReviews.length === 0 && (
              <p>No hay reseñas para esta tienda.</p>
            )}
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const useMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 780);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 780);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobileView;
};

export default ShopProfileData;
