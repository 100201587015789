import React from "react";
import IconComponent from "../IconComponent/IconComponent";

const DetailItem = ({ iconId, text, onClick }) => (
  <div
    onClick={onClick}
    className="flex items-center mb-2 bg-blue-100 px-3 py-2 rounded-lg cursor-pointer hover:bg-blue-200"
  >
    <IconComponent id={iconId} className="mr-2" />
    <h4 className="text-blue-600 font-Poppins text-sm flex-1">{text}</h4>
  </div>
);

export default DetailItem;
