import React, { lazy } from "react";

const HomePage = lazy(() => import("../pages/HomePage"));
const LoginPage = lazy(() => import("../pages/Login"));
const SignupPage = lazy(() => import("../pages/SignupPage"));
const ForgotPasswordPage = lazy(() => import("../pages/ForgotPasswordPage"));
const ActivationPage = lazy(() => import("../pages/ActivationPage"));
const PasswordResetPage = lazy(() => import("../pages/PasswordResetPage"));
const ProductsPage = lazy(() => import("../pages/ProductsPage"));
const BestSellingPage = lazy(() => import("../pages/BestSellingPage"));
const EventsPage = lazy(() => import("../pages/EventsPage"));
const FAQPage = lazy(() => import("../pages/FAQPage"));
const CheckoutPage = lazy(() => import("../pages/CheckoutPage"));
const PaymentPage = lazy(() => import("../pages/PaymentPage"));
const OrderSuccessPage = lazy(() => import("../pages/OrderSuccessPage"));
const ShopCreateSuccessPage = lazy(() =>
  import("../pages/ShopCreateSuccessPage")
);
const ProductDetailsPage = lazy(() => import("../pages/ProductDetailsPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const ShopCreatePage = lazy(() => import("../pages/ShopCreate"));
const SellerActivationPage = lazy(() =>
  import("../pages/SellerActivationPage")
);
const ShopLoginPage = lazy(() => import("../pages/ShopLoginPage"));
const OrderDetailsPage = lazy(() => import("../pages/OrderDetailsPage"));
const TrackOrderPage = lazy(() => import("../pages/TrackOrderPage"));
const UserInbox = lazy(() => import("../pages/UserInbox"));
const SellerPasswordResetPage = lazy(() =>
  import("../pages/SellerPasswordResetPage")
);
const ShopForgotPasswordPage = lazy(() =>
  import("../pages/ShopForgotPasswordPage")
);
const SearchPage = lazy(() => import("../pages/SearchPage"));

export {
  LoginPage,
  SignupPage,
  ForgotPasswordPage,
  ActivationPage,
  PasswordResetPage,
  HomePage,
  ProductsPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ShopCreateSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  SellerPasswordResetPage,
  ShopForgotPasswordPage,
  SearchPage,
};
