const styles = {
    custom_container: "w-11/12 hidden sm:block",
    heading: 'text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]',
    section:'w-11/12 mx-auto my-5',
    productTitle: 'text-[25px] font-[600] font-Roboto text-[#333]',
    productDiscountPrice:"font-bold text-[18px] text-[#333] font-Roboto",
    price:"font-[400] text-[16px] text-[#d55b45] pl-3 mt-[-4px] line-through",
    shop_name:"pt-3 text-[15px] text-blue-400 pb-3",
    active_indicator:"absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
    button: 'w-[120px] bg-[#3D5AFE] h-[40px] my-2 flex items-center justify-center rounded-xl cursor-pointer',
    cart_button: "px-[20px] h-[38px] rounded-[20px] bg-[#1976D2] flex items-center justify-center cursor-pointer",
    cart_button_text:"text-[#fff] text-[16px] font-[600]",
    input:"w-full border p-1 rounded-[5px]",
    activeStatus:"w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
    noramlFlex:"flex items-center",
    iconContainer:"inline-flex items-center h-[24px] w-[24px]",
    btn:"px-4 py-2 rounded text-white font-semibold text-sm uppercase tracking-wider focus:outline-none transition ease-in-out duration-300",
    btn_primary:"bg-blue-700 hover:bg-blue-800",
    btn_secondary:"bg-gray-600 hover:bg-gray-800",


  };
  
  export default styles;
  